import Container from '@material-ui/core/Container';
import React, { ReactElement } from 'react';
import PageLayout from '../layouts/PageLayout';

function AddJobPage(): ReactElement {
  return (
    <PageLayout>
      <Container>to be implemented</Container>
    </PageLayout>
  );
}

export default AddJobPage;
